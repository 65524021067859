import React from 'react'
import {
  CASUAL,
  CHESS,
  CHESS_LEARN,
  GAMES,
} from '../../../components/internal-links'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Blogs from '../../../components/Blogs/Blogs'
import CHESS_LEARN_PAGES from '../../../chess-tags'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../../lib/generate-schema'
import { CHESS_LEARN as CHESS_DOWNLOAD_LINK } from '../../../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'Learn Chess',
    url: CHESS_LEARN,
  },
]

const LearnChess: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={CHESS_DOWNLOAD_LINK}
    >
      <SEO
        title="Chess: Learn everything about chess from scratch | Mega"
        description="Get to know the best websites, gameplay, playing strategies, and how to earn money via online chess. Read the articles listed exclusively for you."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="chess"
        tags={CHESS_LEARN_PAGES}
        prefix={`${CHESS}${process.env.GATSBY_CMS_LEARN_CHESS_PREFIX}` || '/'}
        title={
          <>
            Learn Chess - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnChess
